<template>
  <div>
    <l-table
      ref="lTableRef"
      :module-name="MODULE_NAME"
      :table-columns="tableTrashColumns"
      back-to-list-path="home-customers-financial-info-list"
      :table-config-options="tableConfigOptions()"
    >
      <template #cell(is_active)="{ data }">
        {{ $t(data.value ? 'Active': 'Inactive') }}
      </template>

      <template #cell(actions)="{ data }">
        <div
          class="text-nowrap d-flex"
          style="gap: 8px"
        >
          <feather-icon
            v-b-tooltip.noninteractive.hover.bottom="$t('Delete')"
            icon="LTrashIcon"
            size="16"
            class="featherIcon cursor-pointer border-dotted"
            @click="remove(data.item)"
          />
          <feather-icon
            v-b-tooltip.noninteractive.hover
            :title="$t('Set to active')"
            icon="LLoadIcon"
            size="24"
            class="featherIcon cursor-pointer border-dotted"
            @click="restoreContact(data.item)"
          />
        </div>
      </template>
    </l-table>
  </div>
</template>
<script>

import LTable from '@/views/components/LTable/LTable.vue'
import config from '@/views/main/customers/view/financial-info/config'
import { VBTooltip } from 'bootstrap-vue'

export default {
  name: 'FinancialInfoTrashList',
  components: { LTable },
  directives: {
    'b-tooltip': VBTooltip,
  },
  methods: {
    refetchData() {
      this.$refs.lTableRef.refetchData()
    },
    tableConfigOptions() {
      return {
        endpoint: `${this.MODULE_NAME}/getInActiveList`,
      }
    },
    remove(data) {
      this.confirmNotification(this, data, `${this.MODULE_NAME}/del`)
        .then(() => {
          this.refetchData()
        })
    },
    restoreContact(data) {
      this.confirmNotification(this, data, `${this.MODULE_NAME}/setActive`, {
        title: 'Are you sure you want to reactivate this ?',
        text: '',
        confirmButtonText: 'Reactivate',
      })
        .then(() => {
          this.refetchData()
        })
    },
  },
  setup() {
    const MODULE_NAME = 'financialInfo'
    const { tableTrashColumns, balanceColumns, ACCESS_ABILITY_FINANCIAL_INFO } = config()
    return {
      tableTrashColumns,
      MODULE_NAME,
      balanceColumns,
      ACCESS_ABILITY_FINANCIAL_INFO,
    }
  },
}
</script>
<style scoped>

</style>
