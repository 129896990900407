import {
  SELECT_INPUT, RADIO_GROUP,
  MASK_INPUT, CHECKBOX_LEF,
} from '@/views/components/DynamicForm/constants'
import store from '@/store'

export default function config() {
  const ACCESS_ABILITY_FINANCIAL_INFO = { action: 'change', subject: 'Financial Info' }
  // Table Handlers
  const tableColumns = [
    { key: 'actions', label: '', thStyle: { width: '150px' } },
    { key: 'card_type', label: 'Type' },
    { key: 'network', label: 'Network' },
    { key: 'card_number', label: 'Last Four Digits' },
    // { key: 'cardholder', label: 'Billing Contact' },
    { key: 'billing_address', label: 'Billing Address' },
    { key: 'is_default', label: 'Default' },
  ]
  const tableTrashColumns = [
    { key: 'actions', label: '', thStyle: { width: '150px' } },
    { key: 'card_number', label: 'Last Four Digits' },
    { key: 'is_default', label: 'Default' },
    // { key: 'cardholder', label: 'Billing Contact' },
    { key: 'is_active', label: 'Status' },
  ]
  const balanceColumns = [
    { key: 'operation_type', label: 'Operation Type' },
    { key: 'number', label: 'Number' },
    { key: 'debit', label: 'Debit' },
    { key: 'credit', label: 'Credit' },
    { key: 'balance', label: 'Balance' },
  ]
  const fields = {
    card_number: {
      type: MASK_INPUT,
      label: 'Card Number',
      rules: 'required|length:16',
      options: {
        options: {
          creditCard: true,
        },
      },
      placeholder: 'Type...',
    },
    cardholder_id: {
      type: SELECT_INPUT,
      label: 'Cardholder name',
      rules: 'required',
      store: 'financialInfo/getContacts',
      getList: res => res.data.data.data,
      options: {
        'get-option-label': option => `${option.first_name} ${option.last_name}`,
      },
    },
    billing_address_id: {
      type: SELECT_INPUT,
      label: 'Billing Address',
      rules: 'required',
      store: 'financialInfo/getAddresses',
      getList: res => res.data.data.data,
      options: {
        'get-option-label': option => `${option.street} ${option.city}, ${option.zip}`,
      },
    },
    exp_date: {
      type: MASK_INPUT,
      label: 'Exp Date',
      rules: 'required|max:5',
      placeholder: 'Type...',
      options: {
        options: {
          date: true,
          datePattern: ['m', 'y'],
        },
        raw: false,
      },
    },
    card_type: {
      type: RADIO_GROUP,
      label: 'Card Type',
      rules: 'required',
      list: store.state.listModule.cardTypeList,
    },
    is_active: {
      type: CHECKBOX_LEF,
      label: 'Active',
    },
  }
  return {
    tableColumns,
    tableTrashColumns,
    fields,
    balanceColumns,
    ACCESS_ABILITY_FINANCIAL_INFO,
  }
}
